<template>
  <div>
    <div class="modal-info__wrap-margin">
      <p class="title-mini index-color-text">Журнал изменений блока</p>
    </div>
    <div class="view-tab">
      <div class="row">
        <div class="col s12">
          <ul class="tabs">
            <li class="tab col s6  view-tab__text waves-effect" @click="switchTab('active')" :class="{ 'active-tab': activeTab === 'active' }">Активные</li>
            <li class="tab col s6  view-tab__text waves-effect" @click="switchTab('completed')" :class="{ 'active-tab': activeTab === 'completed' }">Завершенные</li>
          </ul>
        </div>
      </div>

      <!-- Активные записи -->
      <div v-show="activeTab === 'active'" >
        <table v-if="allCommandSetting && allCommandSetting.activeUserCommands.length !== 0">
          <thead>
          <tr>
            <th>Дата создания</th>
            <th>Команда</th>
            <th>Значение</th>
            <th>Статус</th>
          </tr>
          </thead>
         <app-body-table-active-log v-for="(item,index) in allCommandSetting.activeUserCommands" :key="index"  :item_active="item"/>
        </table>
        <empty-logs text="На данный момент активных записей не найдено" v-else/>
      </div>


      <!-- Завершенные записи -->
      <div v-show="activeTab === 'completed'" >
        <table  v-if="allCommandSetting && allCommandSetting.completeUserCommands.length !== 0">
          <thead>
          <tr>
            <th>Дата завершения</th>
            <th>Команда</th>
            <th>Значение</th>
            <th>Статус</th>
          </tr>
          </thead>
          <app-body-table-active-log v-for="(item,index) in allCommandSetting.completeUserCommands" :key="index"  :item_active="item"/>
        </table>
        <empty-logs text="На данный момент завершенных записей не найдено" v-else />
      </div>

    </div>

  </div>
</template>

<script>
  import EmptyLogs from "../Report/EmptyLogs.vue";
  import M from 'materialize-css'
  // import { actionTypesTracker } from '@/store/modules/trackers'
  import { mapState } from 'vuex'
  import AppBodyTableActiveLog from '@/components/Tracker/BodyTableItemActiveLog.vue'



  export default {
    data() {
      return {
        activeTab: 'active', // Устанавливаем активный таб по умолчанию,
        elems: null,
      }
    },
    components: {  AppBodyTableActiveLog, EmptyLogs },
    name: "AppActiveChangeLog",
    methods: {
      switchTab(tab) {
        this.activeTab = tab;
      }
    },
    computed: {
      ...mapState({
        itemTracker: (state) => state.trackers.itemTracker,
        allCommandSetting: (state) => state.trackers.allCommandSetting,
      }),

    },
    mounted() {
      // this.elems = M.Tabs.init(this.$el.querySelectorAll(".tabs"));
      this.$nextTick(() => {
        this.elems = document.querySelectorAll(".tabs");
        M.Tabs.init(this.elems);
        console.log(this.elems, 'jkk')

      });

      // this.$store
      //     .dispatch(actionTypesTracker.getAllCommands, this.itemTracker.serial)
      //     .then(() => {})
      //     .catch((er) => {
      //       console.log("Ошибка", er);
      //     });
    }
  };
</script>

<style scoped>
table {
  font-size: 12px !important;
}
</style>
