<template>
  <form class="form-settings-tracker">
    <div class="row">
      <div class="modal-info__wrap-margin">
        <p class="title-mini index-color-text">Настройки устройства</p>
      </div>
      <div class="col s12">
        <div class="row display-end">
          <div class="input-field col s4">
            <div class="waiting-submit" v-if="normalizedTracker.commandResponse.changeUserPhoneNumber" >
              <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right" >Обновление отправлено</span>
              <img src="../../assets/svg/network-activity.svg" alt="wait">
            </div>
            <input
              placeholder="+7(***)**-**"
              id="disabled"
              type="text"
              name="phoneNumber"
              class="validate input-margin"
              :disabled="normalizedTracker.commandResponse.changeUserPhoneNumber"
              v-model="normalizedTracker.gprsResponse.phoneNumber"
              @input="handleInputChange(1)"
            />
            <label class="active" for="disabled">SIM-карта</label>
          </div>

          <div class="input-field col s4">
            <div class="waiting-submit" v-if="normalizedTracker.commandResponse.changePasswordForSMS">
              <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right"  >Обновление отправлено</span>
              <img src="../../assets/svg/network-activity.svg" alt="wait">
            </div>

            <input
              placeholder="1234"
              id="disabled"
              type="password"
              name="password"
              class="validate input-margin"
              :disabled="normalizedTracker.commandResponse.changePasswordForSMS"
              v-model="normalizedTracker.gprsResponse.password"
              @input="handleInputChange(2)"
            />
            <label class="active" for="password">Пароль</label>
          </div>
          <div class="input-field col s4">
            <select-time-zone @timezoneSelected="timezoneSelected" ref="timeZone" :disable_time="normalizedTracker.commandResponse.changeTimeZone" />
          </div>
        </div>
      </div>
      <div class="col s12">
        <div class="modal-info__title-params">
        <p class="modal-info__title-mini black-text">Настройка SMS параметров</p>
        </div>
        <div class="row display-end">
          <div class="input-field col s4">
            <div class="waiting-submit"  v-if="normalizedTracker.commandResponse.changeUssdBalance" >
              <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right">Обновление отправлено</span>
              <img src="../../assets/svg/network-activity.svg" alt="wait">
            </div>
            <input
              placeholder="(по умолчанию *100#)"
              id="balanceRequest"
              type="text"
              name="balanceRequest"
              class="validate input-margin"
              :disabled="normalizedTracker.commandResponse.changeUssdBalance"
              v-model="normalizedTracker.gprsResponse.balanceRequest"
              @input="handleInputChange(8)"
            />
            <label class="active" for="balanceRequest">Команда баланса</label>
          </div>
          <div class="input-field col s4">
            <div class="waiting-submit" v-if="normalizedTracker.commandResponse.changeLowBalanceAlert">
              <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right" >Обновление отправлено</span>
              <img src="../../assets/svg/network-activity.svg" alt="wait">
            </div>
            <input
              id="lowBalanceAlert"
              type="number"
              min="1"
              name="lowBalanceAlert"
              class="validate input-margin"
              :disabled="normalizedTracker.commandResponse.changeLowBalanceAlert"
              v-model="normalizedTracker.gprsResponse.lowBalanceAlert"
              @input="handleInputChange(7)"
            />
            <label class="active" for="lowBalanceAlert">Порог баланса в рублях</label>
          </div>
          <div class="input-field col s4">
            <div class="waiting-submit" v-if="normalizedTracker.commandResponse.changeSmsLanguage">
              <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right"  >Обновление отправлено</span>
              <img src="../../assets/svg/network-activity.svg" alt="wait">
            </div>
            <label for="viewControl" class="active">Формат SMS:</label>
            <select
                class="select-settings input-margin"
                name="languageSMS"
                id="disabled"
                :disabled="normalizedTracker.commandResponse.changeSmsLanguage"
                v-model="normalizedTracker.gprsResponse.smsLanguage"
                @change="handleInputChange(4)"
            >
              <option
                  v-for="(view, index) in language"
                  :key="index"
                  :value="view.mode"
              >
                {{ view.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col s12">
        <div class="modal-info__title-params">
          <p class="modal-info__title-mini black-text">Режим передачи информации</p>
        </div>
        <div class="row">

          <div class="input-field col s6">
            <label for="workMode" class="active">Режим работы:</label>
            <div class="waiting-submit" v-if="normalizedTracker.commandResponse.changeTransmissionMode">
              <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right" >Обновление отправлено</span>
              <img src="../../assets/svg/network-activity.svg" alt="wait">
            </div>
            <select
              class="validate select-settings input-margin"
              name="workMode"
              id="disabled"
              :disabled="normalizedTracker.commandResponse.changeTransmissionMode"
              v-model="normalizedTracker.gprsResponse.dataRecipientType"
              @change="handleInputChange(11)"
            >
              <option
                v-for="(type, index) in typeWorkMode"
                :key="index"
                :value="type.mode"
              >
                {{ type.name }}
              </option>
            </select>
          </div>
          <div class="input-field col s6">
            <label for="viewControl" class="active">Управление машиной:</label>
            <div class="waiting-submit" v-if="normalizedTracker.commandResponse.changeTransmissionMode">
              <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right"  >Обновление отправлено</span>
              <img src="../../assets/svg/network-activity.svg" alt="wait">
            </div>
            <select
              class="select-settings input-margin"
              name="coordinateDefinitionType"
              id="viewControl"
              :disabled="normalizedTracker.commandResponse.changeTransmissionMode"
              v-model="normalizedTracker.gprsResponse.coordinateDefinitionType"
              @change="handleInputChange(11)"
            >
              <option
                v-for="(view, index) in controlCar"
                :key="index"
                :value="view.mode"
              >
                {{ view.name }}
              </option>
            </select>
          </div>

        </div>
      </div>
      <div class="col s12">
        <div class="modal-info__title-params">
          <p class="modal-info__title-mini black-text">Настройка датчика движения</p>
        </div>
        <div class="row display-end">
          <div class="input-field col s6">
            <label for="viewControl" class="active">Чувствительсть датчика:</label>
            <div class="waiting-submit" v-if="normalizedTracker.commandResponse.changeMotionSensor">
              <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right"  >Обновление отправлено</span>
              <img src="../../assets/svg/network-activity.svg" alt="wait">
            </div>
            <select
                class="select-settings input-margin"
                name="languageSMS"
                id="languageSMS"
                :disabled="normalizedTracker.commandResponse.changeMotionSensor"
                v-model="normalizedTracker.gprsResponse.motionSensitivity"
                @change="handleInputChange(5)"
            >
              <option
                  v-for="(view, index) in motionSensitivity"
                  :key="index"
                  :value="view.mode"
              >
                {{ view.name }}
              </option>
            </select>
          </div>
          <div class="input-field col s6">
            <div class="waiting-submit" v-if="normalizedTracker.commandResponse.changeMotionSensorReactivationTime">
              <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right"  >Обновление отправлено</span>
              <img src="../../assets/svg/network-activity.svg" alt="wait">
            </div>
            <input
                placeholder="10"
                id="disabled"
                type="number"
                min="0"
                name="reactivationMotionTime"
                class="validate input-margin"
                :disabled="normalizedTracker.commandResponse.changeMotionSensorReactivationTime"
                v-model="normalizedTracker.gprsResponse.reactivationMotionTime"
                @input="handleInputChange(6)"
            />
            <label class="active" for="disabled">Повторное срабатывание в сек.</label>
          </div>
        </div>
        <div class="col s12">
          <div class="modal-info__title-params">
            <p class="modal-info__title-mini black-text">Режим снупера</p>
          </div>
          <div class="row">
            <div class="input-field col s6">
              <div class="waiting-submit" v-if="normalizedTracker.commandResponse.changeSnooperMode">
                <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right"  >Обновление отправлено</span>
                <img src="../../assets/svg/network-activity.svg" alt="wait">
              </div>
            <input
                placeholder="5"
                id="disabled"
                type="number"
                min="0"
                name="snoopPeriodCommunication"
                class="validate input-margin"
                :disabled="normalizedTracker.commandResponse.changeSnooperMode"
                v-model="normalizedTracker.gprsResponse.snoopPeriodCommunication"
                @input="handleInputChange(9)"
            />
            <label class="active" for="disabled">Период выхода</label>
              <span class="helper-text" data-error="wrong" data-success="right">Измерение в часах</span>
            </div>
            <div class="input-field col s6">
              <div class="waiting-submit" v-if="normalizedTracker.commandResponse.changeSnooperMode">
                <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right"  >Обновление отправлено</span>
                <img src="../../assets/svg/network-activity.svg" alt="wait">
              </div>
              <input
                  placeholder="5"
                  id="disabled"
                  type="number"
                  min="0"
                  name="dispatchCoordinatesTimes"
                  class="validate input-margin"
                  :disabled="normalizedTracker.commandResponse.changeSnooperMode"
                  v-model="normalizedTracker.gprsResponse.dispatchCoordinatesTimes"
                  @input="handleInputChange(9)"
              />
              <label class="active" for="disabled">Длительность работы в минутах</label>
            </div>
          </div>
        </div>
        <div class="col s12">
          <div class="modal-info__title-params">
            <p class="modal-info__title-mini red-text">SOS</p>
          </div>
          <div class="row">
            <div class="input-field col s12">
              <div class="waiting-submit" v-if="normalizedTracker.commandResponse.changeSosMode">
                <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right"  >Обновление отправлено</span>
                <img src="../../assets/svg/network-activity.svg" alt="wait">
              </div>
              <input
                  placeholder="5"
                  id="disabled"
                  type="number"
                  min="0"
                  name="sosParam"
                  class="validate input-margin"
                  :disabled="normalizedTracker.commandResponse.changeSosMode"
                  v-model="normalizedTracker.gprsResponse.sosParam"
                  @input="handleInputChange(10)"
              />
              <label class="active" for="disabled">Режим поиска (СОС) (по умолчанию передача положения раз в 10 минут).</label>
            </div>
          </div>
        </div>
        <div class="input-field col s12">
          <div class="input-field">
            <div class="waiting-submit" v-if="normalizedTracker.commandResponse.receiveCurrentLocation">
              <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right">Обновление отправлено</span>
              <img src="../../assets/svg/network-activity.svg" alt="wait">
            </div>
            <button
                type="button"
                name="btn_wait"
                :class="requestWaitLocation"
                :disabled="normalizedTracker.commandResponse.receiveCurrentLocation"
                class="col s12 btn waves-effect"
                @click="handleInputChange(16)"
            >
              Запросить местоположение
            </button>
          </div>
          <label class="active" for="disabled">Определение местоположения.</label>
        </div>
<!--        //!ДЛЯ АДМИНА-->
        <div class="modal-info__wrap-margin">
          <p class="title-mini index-color-text">Настройки устройства(Админ)</p>
        </div>
        <div class="col s12">
          <div class="row">
            <div class="input-field col s6">

              <input
                  placeholder="5"
                  id="disabled"
                  type="number"
                  min="0"
                  name="operatingTimeAwakes"
                  class="validate input-margin"
                  :disabled="normalizedTracker.commandResponse.changeWakesUpTime"
                  v-model="normalizedTracker.gprsResponse.operatingTimeAwakes"
                  @input="handleInputChange(12)"
              />
              <div class="waiting-submit" v-if="normalizedTracker.commandResponse.changeWakesUpTime">
                <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right">Обновление отправлено</span>
                <img src="../../assets/svg/network-activity.svg" alt="wait">
              </div>
              <label class="active" for="disabled">Время при подключении прибора в минутах.</label>
            </div>
            <div class="input-field col s6">
              <div class="waiting-submit" v-if="normalizedTracker.commandResponse.changeApnParameters">
                <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right">Обновление отправлено</span>
                <img src="../../assets/svg/network-activity.svg" alt="wait">
              </div>
              <input
                  placeholder="5"
                  id="disabled"
                  type="text"
                  name="apnParams"
                  class="validate input-margin"
                  :disabled="normalizedTracker.commandResponse.changeApnParameters"
                  v-model="normalizedTracker.gprsResponse.apnParams"
                  @input="handleInputChange(14)"
              />
              <label class="active" for="disabled">Установка APN параметров.</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col s12">
        <div class="row">
          <div class="input-field col s12">
            <div class="waiting-submit" v-if="normalizedTracker.commandResponse.changeServerInstall">
              <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right">Обновление отправлено</span>
              <img src="../../assets/svg/network-activity.svg" alt="wait">
            </div>
            <input
                placeholder="5"
                id="disabled"
                type="text"
                name="serverAddress"
                class="validate input-margin"
                :disabled="normalizedTracker.commandResponse.changeServerInstall"
                v-model="normalizedTracker.gprsResponse.serverAddress"
                @input="handleInputChange(13)"
            />
            <label class="active" for="disabled">Установка сервера для передачи данных.</label>
          </div>

          <div class="input-field col s12">
            <div class="input-field">
              <div class="waiting-submit" v-if="normalizedTracker.commandResponse.receiveDeviceCode">
                <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right">Обновление отправлено</span>
                <img src="../../assets/svg/network-activity.svg" alt="wait">
              </div>
              <button
                  type="button"
                  name="btn_wait"
                  :class="requestWait"
                  class="col s12 btn waves-effect"
                  :disabled="normalizedTracker.commandResponse.receiveDeviceCode"
                  @click="handleInputChange(15)"
              >
                Запросить код устройства
              </button>
            </div>
            <label class="active" for="disabled">Команда запроса кода.</label>
          </div>
          <div class="input-field col s12">
            <div class="input-field">
              <div class="waiting-submit" v-if="normalizedTracker.commandResponse.receiveOperatingParameters">
                <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right">Обновление отправлено</span>
                <img src="../../assets/svg/network-activity.svg" alt="wait">
              </div>
              <button
                  type="button"
                  name="btn_wait"
                  :class="requestWaitParamsWork"
                  :disabled="normalizedTracker.commandResponse.receiveOperatingParameters"
                  class="col s12 btn waves-effect"
                  @click="handleInputChange(17)"
              >
                Запросить параметры
              </button>
            </div>
            <label class="active" for="disabled">Запрос параметров работы.</label>
          </div>
          <div class="input-field col s12">
            <div class="input-field">
              <div class="waiting-submit" v-if="normalizedTracker.commandResponse.receiveDeviceReboot">
                <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right">Обновление отправлено</span>
                <img src="../../assets/svg/network-activity.svg" alt="wait">
              </div>
              <button
                  type="button"
                  name="btn_wait"
                  :disabled="normalizedTracker.commandResponse.receiveDeviceReboot"
                  :class="requestWaitParamsReload"
                  class="col s12 btn waves-effect"
                  @click="handleInputChange(18)"
              >
                Перезагрузить параметров
              </button>
            </div>
            <label class="active" for="disabled">Запрос на перезагрузку параметров.</label>
          </div>
<!--          <div class="input-field col s12">-->
<!--            <div class="input-field">-->
<!--              <div class="waiting-submit" v-if="normalizedTracker.commandResponse.receiveRegularPackage">-->
<!--                <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right">Обновление отправлено</span>-->
<!--                <img src="../../assets/svg/network-activity.svg" alt="wait">-->
<!--              </div>-->
<!--              <button-->
<!--                  type="button"-->
<!--                  name="btn_wait"-->
<!--                  :class="requestWaitParamsPackage"-->
<!--                  :disabled="normalizedTracker.commandResponse.receiveRegularPackage"-->
<!--                  class="col s12 btn waves-effect"-->
<!--                  @click="handleInputChange(19)"-->
<!--              >-->
<!--               Запросить пакет-->
<!--              </button>-->
<!--            </div>-->
<!--            <label class="active" for="disabled">Запросить регулярный пакет данных.</label>-->
<!--          </div>-->
          <div class="input-field col s12">
            <div class="input-field">
              <div class="waiting-submit" v-if="normalizedTracker.commandResponse.receiveDeviceModel">
                <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right">Обновление отправлено</span>
                <img src="../../assets/svg/network-activity.svg" alt="wait">
              </div>
              <button
                  type="button"
                  name="btn_wait"
                  :class="requestWaitParamsModel"
                  :disabled="normalizedTracker.commandResponse.receiveDeviceModel"
                  class="col s12 btn waves-effect"
                  @click="handleInputChange(20)"
              >
                Запросить модель устройства
              </button>
            </div>
            <label class="active" for="disabled">Запросить модель устройства.</label>
          </div>
        </div>
      </div>

    </div>
  </form>
</template>

<script>
import M from "materialize-css";
import SelectTimeZone from "../SelectTimeZone.vue";
import { actionTypesTracker } from '@/store/modules/trackers'

export default {
  name: "AppSettingsTracker",
  components: { SelectTimeZone },
  props: {
    normalizedTracker: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      // new
      changeCommands: [],
      isActiveWaiting: true,
      isActiveWaitingLocation: true,
      isActiveWaitingWork: true,
      isActiveWaitingReload: true,
      isActiveWaitingPackage: true,
      isActiveWaitingModel: true,
      selectedTimezone: "",
      typeMode: "",
      viewControl: "",
      languageSMS: "",
      typeWorkMode: [
        {name: "Только на сервер", mode: 'S'},
        {name: "Только по SMS",mode: "U"},
        {name: "На сервер и по SMS", mode: "A"}
      ],
      controlCar: [{name:"GPS и LBS", mode: 'G'}, {name: "Только LBS", mode: "L"}],
      language: [{name: "Русский", mode:'R'},{name: "Английский", mode:'E'}],
      motionSensitivity: [{mode:0, name: "Выключен"}, {mode:1, name: "Сильная"},  {mode:2, name: "Средняя"}, {mode:3, name: "Слабая"}]
    };
  },
  computed: {
    requestWait() {
      return {
        'index-color': this.isActiveWaiting,
        'white-text': this.isActiveWaiting,
        'gray-color': !this.isActiveWaiting,
        'grey-text': !this.isActiveWaiting,

      }
    },
    requestWaitLocation() {
      return {
        'index-color': this.isActiveWaitingLocation,
        'white-text': this.isActiveWaitingLocation,
        'gray-color': !this.isActiveWaitingLocation,
        'grey-text': !this.isActiveWaitingLocation,

      }
    },
    requestWaitParamsWork() {
      return {
        'index-color': this.isActiveWaitingWork,
        'white-text': this.isActiveWaitingWork,
        'gray-color': !this.isActiveWaitingWork,
        'grey-text': !this.isActiveWaitingWork,

      }
    },
    requestWaitParamsReload() {
      return {
        'index-color': this.isActiveWaitingReload,
        'white-text': this.isActiveWaitingReload,
        'gray-color': !this.isActiveWaitingReload,
        'grey-text': !this.isActiveWaitingReload,

      }
    },
    requestWaitParamsPackage() {
      return {
        'index-color': this.isActiveWaitingPackage,
        'white-text': this.isActiveWaitingPackage,
        'gray-color': !this.isActiveWaitingPackage,
        'grey-text': !this.isActiveWaitingPackage,

      }
    },
    requestWaitParamsModel() {
      return {
        'index-color': this.isActiveWaitingModel,
        'white-text': this.isActiveWaitingModel,
        'gray-color': !this.isActiveWaitingModel,
        'grey-text': !this.isActiveWaitingModel,

      }
    }
    },

  methods: {
    timezoneSelected(timezone) {
      this.selectedTimezone = timezone;
      this.handleInputChange(3)
    },
    updateNewSettings() {
      // отправка команд на устройства
      if( this.changeCommands.length !== 0) {
        this.$store
            .dispatch(actionTypesTracker.sendCommands,
                { "serial": this.normalizedTracker.serial,
                  "beaconCommandList":this.changeCommands } )
            .then(() => {
              M.FormSelect.init(this.$el.querySelectorAll(".select-settings"));
              this.$refs.timeZone.updateSelect();
              this.changeCommands = []
              this.$store
                  .dispatch(actionTypesTracker.getAllCommands, this.normalizedTracker.serial)
                  .then(() => {})
                  .catch((er) => {
                    console.log("Ошибка", er);
                  });
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      }
    },
    closeModal() {
      if(this.changeCommands.length !== 0) {
        this.$emit('openModalWar', true)
        console.log('Есть изменения в компоненте Settings')
      } else {
        this.$emit('openModalWar', false)
        console.log('нет изменения в компоненте Settings')
      }
    },
    handleInputChange(keyCommand) {
      // Функция для создания или обновления объекта изменения
      const updateChangeCommand = (keyCommand, commandValue) => {
        // Поиск объекта изменения с соответствующим keyCommand
        let changeCommand = this.changeCommands.find(command => command.keyCommand === keyCommand);

        // Если объект не найден, создаем новый
        if (!changeCommand) {
          changeCommand = {
            keyCommand: keyCommand,
            command: ''
          };
          this.changeCommands.push(changeCommand);
        }

        // Обновляем значение команды в объекте изменения
        changeCommand.command = commandValue;
      };

      // Обработка каждого keyCommand
      switch (keyCommand) {
        case 1:

          updateChangeCommand(1, this.normalizedTracker.gprsResponse.phoneNumber);
          break;
        case 2:
          updateChangeCommand(2, this.normalizedTracker.gprsResponse.password);
          break;
        case 3:
          updateChangeCommand(3, this.selectedTimezone);
          break;
        case 4:
          var selectedLanguage = this.language.find(lang => lang.mode === this.normalizedTracker.gprsResponse.smsLanguage);
          if (selectedLanguage) {
            updateChangeCommand(4, selectedLanguage.mode);
          }
          break;
        case 5:
          var selectedMotionSens = this.motionSensitivity.find(mot => mot.mode === this.normalizedTracker.gprsResponse.motionSensitivity);

          if (selectedMotionSens) {
            updateChangeCommand(5, selectedMotionSens.mode);
          }
          break;
        case 6:
          updateChangeCommand(6, this.normalizedTracker.gprsResponse.reactivationMotionTime);
          break;
        case 7:
          updateChangeCommand(7, this.normalizedTracker.gprsResponse.lowBalanceAlert);
          break;
        case 8:
          updateChangeCommand(8, this.normalizedTracker.gprsResponse.balanceRequest);
          break;
        case 9:
          var snoopPeriodCommunication = this.normalizedTracker.gprsResponse.snoopPeriodCommunication;
          var dispatchCoordinatesTimes = this.normalizedTracker.gprsResponse.dispatchCoordinatesTimes;
          var combinedValue = `${snoopPeriodCommunication};${dispatchCoordinatesTimes}`;

          updateChangeCommand(9, combinedValue);

          break;
        case 10:
          updateChangeCommand(10, this.normalizedTracker.gprsResponse.sosParam);
          break;
        case 11:
          var selectedDataType = this.typeWorkMode.find(type => type.mode === this.normalizedTracker.gprsResponse.dataRecipientType);
          var selectedCoordinateType = this.controlCar.find(type => type.mode === this.normalizedTracker.gprsResponse.coordinateDefinitionType);
          var combinedValueType = `${selectedDataType.mode};${selectedCoordinateType.mode}`;
          if (combinedValueType) {
            updateChangeCommand(11, combinedValueType);
          }
          break;
          //!ДЛЯ АДМИНА
        case 12:
          updateChangeCommand(12, this.normalizedTracker.gprsResponse.operatingTimeAwakes);
          break;
        case 13:
          updateChangeCommand(13,this.normalizedTracker.gprsResponse.serverAddress)
          break;
        case 14:
          updateChangeCommand(14,this.normalizedTracker.gprsResponse.apnParams)
          break;
        case 15:
          //КНОПКУ СДЕЛАТЬ В ОЖИДАНИЕ
          this.isActiveWaiting = !this.isActiveWaiting;
          updateChangeCommand(15,'Команда запроса кода')
          break;
        case 16:
          //КНОПКУ СДЕЛАТЬ В ОЖИДАНИЕ
          this.isActiveWaitingLocation = !this.isActiveWaitingLocation;
          updateChangeCommand(16,'Команда запроса местоположения')
          break;
        case 17:
          this.isActiveWaitingWork = !this.isActiveWaitingWork
          updateChangeCommand(17,'Запрос параметров работы')
          break;
        case 18:
          this.isActiveWaitingReload = !this.isActiveWaitingReload
          updateChangeCommand(18,'Перезагрузка устройства')
          break;
        case 19:
          this.isActiveWaitingPackage = !this.isActiveWaitingPackage
          updateChangeCommand(19,'Запрос пакета данных')
          break;
        case 20:
          this.isActiveWaitingModel = !this.isActiveWaitingModel
          updateChangeCommand(20,'Запрос модели')
          break;


          // Добавьте дополнительные случаи, если есть другие поля ввода
      }
    }

  },
  mounted() {
    M.FormSelect.init(this.$el.querySelectorAll(".select-settings"));
  },
};
</script>

<style scoped>

</style>
