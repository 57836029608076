<template>
  <div class="blockSide">
    <Header @getOpenReport="getOpenReport" />

    <ul id="slide-out" class="sidenav sidenav-position">
      <div
        class="sidebar-close index-color"
        v-if="this.modalInstance && this.modalInstance.isOpen === true"
      >
        <div
          class="sidebar-add__block modal-trigger"
          data-target="modalAddBlock"
        >
          <a class="icon-add" @click="closeSideBar()"
            ><i class="material-icons">add</i></a
          >
          <p class="sidebar-add__text">Добавить устройство</p>
        </div>
        <a class="icon-close" @click="closeSideBar()"
          ><i class="material-icons">close</i></a
        >
      </div>
      <div class="sidenav__select-wrap">
        <div class="input-field col s12 m6">
          <div class="row" style="margin-bottom: 0px !important">
            <!-- <div
              class="chip cursor"
              v-bind:class="chipColorOrange"
              @click="searchNameTimer"
            >
              Имя
            </div>
            <div
              class="chip cursor"
              v-bind:class="chipColorGreen"
              @click="searchAddressTimer"
            >
              Адрес
            </div> -->
            <div class="col-xs-8 col-xs-offset-2">
              <form action="#" method="get" id="searchForm" class="input-group">
                <input
                  type="text"
                  class="input-group__input"
                  name="search"
                  v-model="search"
                  placeholder="Поиск по имени"
                />
                <span class="input-group__btn">
                  <i class="material-icons" @click="onChange(search)">search</i>
                </span>
              </form>
            </div>
          </div>

          <select
            class="icons sidenav__select"
            v-model="selectList"
            @change="onChangeSelected"
          >
            <option value="" disabled selected>Выберите тип</option>
            <option value="3">Трекер</option>
<!--            <option value="1" selected>Таймеры</option>-->

            <!-- <option value="2">Демирование</option> -->
          </select>
        </div>
      </div>
      <!-- БЛОК SIDENAV TIMER -->
      <div
        class=""
        style="height: calc(100% - 100px)"
        v-if="this.selectList === '1'"
      >
        <div
          class=""
          style="overflow-y: auto; height: calc(100% - 77px)"
          v-if="lights"
        >
          <!-- @itemData="itemData(item)" -->
          <item-list
            v-for="(item, index) in filteredListLights"
            :key="item.id"
            @itemData="itemData"
            @zoomToMarker="zoomToMarker(item)"
            @itemDataHistory="itemDataHistory(item)"
            @updateCheck="updateCheck()"
            v-bind:item_data="item"
            v-bind:index="index"
            v-model="checked"
          />
        </div>
      </div>
      <div
        class="sidenav__checkbox-all index-color"
        v-if="this.selectList === '1'"
      >
        <p>
          <label>
            <input type="checkbox" class="filled-in" v-model="selectAllTimer" />

            <span class="title-text">Выбрать все</span>
          </label>
        </p>
        <div
          class="sidenav__send-button tooltipped modal-trigger"
          data-target="modalAllTimers"
          name="action"
          data-position="top"
          :data-tooltip="'Отправить всем'"
          v-if="this.checked.length > 1"
        >
          <i class="material-icons white-text right">send</i>
        </div>
      </div>
      <!-- БЛОК SIDENAV DIMER -->
      <div
        class=""
        style="height: calc(100% - 155px)"
        v-if="this.selectList === '2'"
      >
        <div
          class=""
          style="overflow-y: auto; height: calc(100% - 77px)"
          v-if="demir"
        >
          <item-demir-list
            v-for="item in filteredListDemir"
            :key="item.dimmerId"
            @zoomToMarker="zoomToMarker(item)"
            @updateCheckDimer="updateCheckDimer()"
            @clickSettings="clickSettings()"
            v-bind:item_data="item"
            v-model="checkedDemir"
          ></item-demir-list>
        </div>
      </div>
      <div
        class="sidenav__checkbox-all index-color"
        v-if="this.selectList === '2'"
      >
        <p>
          <label>
            <input type="checkbox" class="filled-in" v-model="selectAllDimer" />

            <span class="title-text">Выбрать все</span>
          </label>
        </p>
        <div
          class="sidenav__send-button tooltipped modal-trigger"
          data-target="modalAllSettingsDemmir"
          name="action"
          data-position="top"
          :data-tooltip="'Отправить всем'"
          v-if="this.checkedDemir.length > 1"
        >
          <i class="material-icons white-text right">send</i>
        </div>
      </div>
      <div style="height: calc(100% - 100px)" >
      <div class=""    style="overflow-y: auto; height: calc(100% - 100px)">
      <!-- БЛОК SIDENAV TRACKER -->
      <list-trackers
        v-if="this.selectList === '3'"
        @zoomToMarker="zoomToMarker"
      />
      </div>
      </div>
    </ul>
    <!-- v-if="!this.isOpenSideNave" -->

    <a
      data-target="slide-out"
      style="margin: 20px 0px 0px 20px; position: absolute; z-index: 100"
      class="sidenav-trigger btn-floating btn-large index-color"
      @click="openSideBar"
      ><i class="material-icons">menu</i></a
    >
  </div>
</template>

<script>
import M from "materialize-css";
import Header from "./Header.vue";
import { mapState } from "vuex";
import ItemList from "./ItemList.vue";
import { actionTypes } from "@/store/modules/lights";
import { actionTypesDemir } from "@/store/modules/demir";
import ItemDemirList from "./ItemDemirList.vue";
import ListTrackers from "./Tracker/ListTrackers.vue";

export default {
  name: "AppSideNav",
  data() {
    return {
      checked: [],
      checkedDemir: [],

      // isSearchTimerName: false,
      // isSearchTimerAddress: false,
      modalInstance: null,
      modalTooltip: null,
      modalSelect: null,
      selectList: "2",
      isOpenSideNave: false,
      isCheckAll: false,
      isCheckAllDemir: false,
      search: "",
    };
  },
  components: {
    Header,
    ItemList,
    ItemDemirList,
    ListTrackers,
  },
  methods: {
    itemData(items) {
      console.log(items, "sideNav");
      this.$emit("itemIndex", items);
    },
    // itemTracker(items) {
    //   console.log(items, "itemTracker");
    //   this.$emit("itemTrackerData", items);
    // },
    clickSettings() {
      console.log("clickSettings");
      this.$emit("clickSettings");
    },
    zoomToMarker(items) {
      this.$emit("zoomToMarker", items);
    },
    updateCheck() {
      this.$store.dispatch(actionTypes.checkedTimer, this.checked);
    },
    updateCheckDimer() {
      this.$store.dispatch(actionTypesDemir.checkedDemir, this.checkedDemir);
      // console.log("updateCheckDimer updateCheckDimer");
    },
    getOpenReport() {
      this.$emit("getOpenReport");
      this.closeSideBar();
    },

    onChangeSelected() {
      if (this.selectList === "1") {
        this.$store
          .dispatch(actionTypes.getList)
          .then(() => {
            // console.log(resp, "ТАЙМЕРЫ");
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
      } else {
        this.$store
          .dispatch(actionTypesDemir.getAllDemir)
          .then(() => {
            // console.log(resp, "ДЕМИРОВАНИЕ");
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
      }
    },

    itemDataHistory(items) {
      this.$emit("itemIndex", items);
      this.$store
        .dispatch(actionTypes.getHistoryTimer, {
          unitId: items.id,
          type: items.type,
        })
        .then((resp) => {
          console.log(resp);
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    async closeSideBar() {
      await this.modalInstance.close();

      this.isOpenSideNave = false;
    },
    openSideBar() {
      this.modalInstance.open();
      this.isOpenSideNave = true;
    },

    // searchNameTimer() {
    //   this.isSearchTimerName = !this.isSearchTimerName;
    // },
    // searchAddressTimer() {
    //   this.isSearchTimerAddress = !this.isSearchTimerAddress;
    // },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.lights.isSubmitting,
      lights: (state) => state.lights.lights,
      demir: (state) => state.demir.allDemir,
    }),
    // chipColorOrange: function () {
    //   return {
    //     orange: this.isSearchTimerName,
    //   };
    // },
    // chipColorGreen: function () {
    //   return {
    //     green: this.isSearchTimerAddress,
    //   };
    // },
    searchReact() {
      return this.search;
    },

    filteredListLights() {
      if (this.search) {
        return this.lights.filter((post) => {
          return post.nameTimer
            .toLowerCase()
            .includes(this.search.toLowerCase());
        });
      } else {
        return this.lights;
      }
    },
    filteredListDemir() {
      if (this.search) {
        return this.demir.filter((post) => {
          console.log(post, "post.name.toLowerCase()");
          if (post.name !== null) {
            return post.name.toLowerCase().includes(this.search.toLowerCase());
          }
        });
      } else {
        return this.demir;
      }
    },
    selectAllTimer: {
      get: function () {
        return this.lights ? this.checked.length == this.lights.length : false;
      },
      set: function (value) {
        var checked = [];

        if (value) {
          this.lights.forEach(function (lights) {
            checked.push(lights);
          });
        }

        this.checked = checked;
        this.$store.dispatch(actionTypes.checkedTimer, this.checked);
      },
    },
    selectAllDimer: {
      get: function () {
        return this.demir
          ? this.checkedDemir.length == this.demir.length
          : false;
      },
      set: function (value) {
        var checkedDemir = [];

        if (value) {
          this.demir.forEach(function (demir) {
            checkedDemir.push(demir);
          });
        }

        this.checkedDemir = checkedDemir;
        this.$store.dispatch(actionTypesDemir.checkedDemir, this.checkedDemir);
      },
    },
    // checkedTracker
  },

  mounted() {
    this.$nextTick(function () {
      const elem = document.querySelector(".sidenav");
      this.modalInstance = M.Sidenav.init(elem);
      this.openSideBar();

      var elems = document.querySelectorAll("select");
      this.modalSelect = M.FormSelect.init(elems);

      var elemsTool = document.querySelectorAll(".tooltipped");
      this.modalTooltip = M.Tooltip.init(elemsTool);
    });
  },
};
</script>

<style>
#sidenav-1 {
  margin-top: 64px;
}
#slide-out {
  margin-top: 64px;
}

#dropdown1,
#dropdown2 {
  margin-top: 64px;
}

.dropdown-content li > a {
  color: #00bcd4 !important;
}
.sidenav {
  width: 360px !important;
}
.side-nav li.active {
  background-color: #00bcd4 !important;
}

.side-nav li.user-li.active {
  background-color: #ac3973 !important;
}

.side-nav li.active.lone a,
.side-nav li.active ul li:first-child,
.side-nav li.active.lone a svg,
.side-nav li.active ul li div.collapsible-header p svg {
  color: #ffffff !important;
}
.sidenav .collapsible li,
.sidenav.fixed .collapsible li {
  padding: 0;
  flex: 1 0 auto;
  max-height: 80%;
}
.my-icon {
  font-size: 1.5em;
  margin-right: 14px;
  color: #666666;
}

.changed {
  margin-left: 5px;
  color: #666666;
}

.no-margin {
  margin: 0px;
}
.sidenav-overlay {
  height: 0px;
  width: 0px;
}
.user-li {
  background-color: #c6538c !important;
}
</style>
