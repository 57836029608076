<template>
  <div>

    <label for="timezone">Выберите часовой пояс:</label>
    <div class="waiting-submit"  v-if="disable_time">
      <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right" >Обновление отправлено</span>
      <img src="../assets/svg/network-activity.svg" alt="wait">
    </div>
    <select
      class="select"
      name="timezone"
      id="timezone"
      :disabled="disable_time"
      v-model="selectedTimezone"
      @change="handleChange"
    >
      <option
        v-for="(timezone, index) in timezones"
        :key="index"
        :value="timezone.value"
      >
        {{ timezone.label }}
      </option>
    </select>

  </div>
</template>

<script>
// import moment from "moment-timezone";
import M from "materialize-css";
export default {
  data() {
    return {
      selectedTimezone: null,
      modalSelect: null,
      timezones: [],
    };
  },
  props: {
    disable_time: {
      type: Boolean,
      default: false // значение по умолчанию
    }
  },

  created() {
    // Получаем список доступных часовых поясов от -12:00 до +12:00 с шагом в 1 час
    for (let i = -12; i <= 12; i++) {
      const offset = i < 0 ? i : `+${i}`; // Добавляем "+" перед положительными числами
      this.timezones.push({
        value: `${offset}:00`,
        label: `UTC ${offset}:00`,
      });
    }
  },
  methods: {
    handleChange() {
      // Передаем выбранный часовой пояс в родительский компонент
      this.$emit("timezoneSelected", this.selectedTimezone);
    },
    updateSelect() {
      M.FormSelect.init(this.$el.querySelectorAll(".select"));
    }
  },
  mounted() {
    M.FormSelect.init(this.$el.querySelectorAll(".select"));
    // var elems = document.querySelector(".select");
    // this.modalSelect = M.FormSelect.init(elems);
  },
};
</script>
