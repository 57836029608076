import trackersAPi from "@/api/trackers.js";

const state = {
  isLoadingDownload: false, //ожидание запроса
  validationErrors: null,
  responseReport: null,
};

export const mutationTypes = {
  downloadReportStart: "[download] downloadReportStart",
  downloadReportSuccess: "[download] downloadReportSuccess",
  downloadReportFailure: "[download] downloadReportFailure",
};
export const actionTypesDownload = {
  downloadReport: "[download] downloadReport",
};

const mutations = {
  //Скачивание отчета
  [mutationTypes.downloadReportStart](state) {
    state.isLoadingDownload = true;
    state.validationErrors = null;
  },
  [mutationTypes.downloadReportSuccess](state, items) {
    state.isLoadingDownload = false;
    state.responseReport = items;
  },
  [mutationTypes.downloadReportFailure](state, payload) {
    state.isLoadingDownload = false;
    state.validationErrors = payload;
  },
};
const actions = {
  [actionTypesDownload.downloadReport]: (context, info) => {
    const { commit } = context;
    return new Promise((resolve, reject) => {
      commit(mutationTypes.downloadReportStart);
      trackersAPi
        .downloadReport(info)
        .then((response) => {
          console.log("====================================");
          console.log(response);
          console.log("====================================");
          const url = URL.createObjectURL(
            new Blob([response.data], { type: "application/vnd.ms-excel" })
          );
          const fileName = `Doc`;
          const reportData = {
            url,
            fileName,
          };
          commit(mutationTypes.downloadReportSuccess, reportData);
          resolve(reportData);
        })
        .catch((error) => {
          commit(mutationTypes.downloadReportFailure, error);
          reject(error);
        });
    });
  },
};
export default {
  state,
  mutations,
  actions,
};
