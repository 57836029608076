<template>
  <main>
    <div style="height: 100vh; width: 100%; position: fixed">
      <l-map
        :zoom="zoom"
        :center="center"
        ref="myMap"
        :options="{ zoomControl: false }"
        @click="addMarker"
      >
        <LTileLayer :url="url"></LTileLayer>

        <div v-for="(item, index) in checkedTimer" :key="index">
          <l-marker
            ref="markerkk"
            @click="openPopUp(item.coordinate)"
            :lat-lng="item.coordinate"
            :icon="
              item.temperature <= 50
                ? iconTimerGreen
                : item.temperature >= 70
                ? iconTimerRed
                : iconTimerYellow
            "
          >
            <MapPopup
              v-bind:item_light="item"
              v-bind:place="place"
              @itemData="itemData(item)"
            />
          </l-marker>
        </div>
        <div v-for="item in checkedDemir" :key="item.dimmerId">
          <l-marker
            @click="openPopUp(item.coordinate)"
            :lat-lng="item.coordinate"
            :icon="iconDemir"
          >
            <MapPopupDemir
              v-bind:item_demir="item"
              v-bind:place="place"
              @itemData="itemData(item)"
            />
          </l-marker>
        </div>
        <div v-for="(item,index) in checkedTrackers" :key="item.id">
          <l-marker
            @click="openPopUp(item.coordinate)"
            :lat-lng="item.coordinate"
            :icon="iconTracker"
          >
            <MapPopupTracker v-bind:item_tracker="item" :index="index" v-bind:place="place" />
          </l-marker>
        </div>

        <l-control-zoom class="leaflet-control-zoom" position="topright">
        </l-control-zoom>
        <!-- </LMap> -->
      </l-map>
    </div>
    <div class="row" v-if="this.openCart && this.markers.length">
      <div class="col right s12 m3">
        <div class="card dark-green-color">
          <div class="card-content white-text">
            <span class="card-title">Определение координат</span>
            <p>
              Широта:
              {{ this.markers[0].lat }}
            </p>
            <p>
              Долгота:
              {{ this.markers[0].lng }}
            </p>
          </div>
          <div class="card-action">
            <div class=""><p class="btn-cart" @click="closeCart">Ок</p></div>
            <div class="">
              <p class="btn-cart" @click="copy">Копировать координаты</p>
            </div>

            <!-- <a href="">Ок</a> -->
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import M from "materialize-css";

import {
  LMap,
  LTileLayer,
  LMarker,
  LControlZoom,
  // LPopup,
  // LLayerGroup,
} from "vue2-leaflet";
import { mapState } from "vuex";
import { icon } from "leaflet";
import { actionTypes } from "@/store/modules/lights";
import { actionTypesDemir } from "@/store/modules/demir";
import axios from "axios";
import MapPopupDemir from "./MapPopup/MapPopupDemir.vue";
import MapPopup from "./MapPopup/MapPopup.vue";
import MapPopupTracker from "./MapPopup/MapPopupTracker.vue";

export default {
  name: "Map",

  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControlZoom,
    // LPopup,

    MapPopupTracker,
    MapPopup,
    MapPopupDemir,
    // LLayerGroup,
  },

  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 14,
      //Талдыкурган
      center: [54.982301, 82.893469],
      modalInstance: null,
      modalTooltip: null,
      searchCoord: {},
      place: "...",
      bounds: null,
      markers: [],
      openCart: true,
      // icon: icon({
      //   iconUrl: require("../assets/img/light2.png"),
      //   iconSize: [50, 50],
      //   iconAnchor: [16, 37],
      // }),
      iconTimerGreen: icon({
        iconUrl: require("../assets/img/timerGreen.svg"),
        iconSize: [35, 35],
        iconAnchor: [16, 37],
      }),
      iconTimerYellow: icon({
        iconUrl: require("../assets/img/timerYellow.svg"),
        iconSize: [35, 35],
        iconAnchor: [16, 37],
      }),
      iconTimerRed: icon({
        iconUrl: require("../assets/img/timerRed.svg"),
        iconSize: [35, 35],
        iconAnchor: [16, 37],
      }),
      iconMeter: icon({
        iconUrl: require("../assets/img/meter.png"),
        iconSize: [35, 35],
        iconAnchor: [16, 37],
      }),
      iconDemir: icon({
        iconUrl: require("../assets/img/street-light.png"),
        iconSize: [35, 35],
        iconAnchor: [16, 37],
      }),
      iconTracker: icon({
        iconUrl: require("../assets/svg/carBefore.svg"),
        iconSize: [45, 45],
        iconAnchor: [16, 37],
      }),
    };
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.lights.isSubmitting,
      lights: (state) => state.lights.lights,
      checkedTimer: (state) => state.lights.checkedTimer,
      checkedDemir: (state) => state.demir.checkedDemir,
      checkedTrackers: (state) => state.trackers.checkedTrackers,
    }),
  },

  methods: {
    // openPopUp(latLng) {
    //   console.log(latLng);
    //   // console.log(this.$refs.features);
    //   // this.caller = caller;
    //   // this.$refs.features.mapObject.openPopup(latLng);
    // },
    getProperty(prop) {
      this.$store
        .dispatch(actionTypesDemir.getProperty, prop)
        .then(() => {})
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    openPopUp(latLng) {
      this.searchCoord = {
        lat: latLng[0],
        lng: latLng[1],
      };
      //ZOOM
      const mapComponent = this.$refs.myMap;
      mapComponent.mapObject.flyTo(this.searchCoord, 15);
      // const mapMarker = this.$refs.marker;
      // this.$refs.markerkk.openPopup();
      // console.log(mapComponent.popupopen());
      // console.log(this.$refs.popup.openOn());

      // leafletObject.openPopup()

      // mapComponent.mapObject.openPopup();
      axios
        .get(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${this.searchCoord.lat}&lon=${this.searchCoord.lng}&zoom=18&addressdetails=1`
        )
        .then((response) => {
          this.place = response.data.display_name;
        })
        .catch(() => {
          this.place = "Место не найдено";
        });
    },

    handleClickTimer() {
      console.log("handleClickTimer");
      this.$emit("itemDataHistory", this.item_data);
    },
    itemData(items) {
      console.log(items, "Leaflet");
      this.$emit("itemIndex", items);
    },
    addMarker(event) {
      this.openCart = true;
      this.markers = [];
      console.log(event.latlng);
      // console.log(event.latlng);
      this.markers.push(event.latlng);
    },
    closeCart() {
      // try {

      this.openCart = false;
      // } catch (e) {
      //   throw e;
      // }
    },
    copy() {
      // navigator.clipboard.writeText(this.markers);
      this.openCart = false;
      this.$store
        .dispatch(actionTypes.copyCoordinate, this.markers)
        .then(() => {})
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
  },
  mounted() {
    // const elems = document.querySelectorAll(".dropdown-trigger");
    // this.modalInstance = M.Dropdown.init(elems);

    var elemsTool = document.querySelectorAll(".tooltipped");
    this.modalTooltip = M.Tooltip.init(elemsTool);
  },
};
</script>
<style scoped>
.btn-cart {
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #ff7043;
}
.leaflet-touch .leaflet-bar a {
  display: inline-block;
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  background-color: #26a69a;
  border-radius: 50%;
  transition: background-color 0.3s;
  cursor: pointer;
  vertical-align: middle;
}
.leaflet-control-zoom {
  margin: 0px 50px 50px 0px !important;
}
.leaflet-control-zoom a {
  color: white !important;
  background: rgba(0, 0, 0, 0.5) !important;
  transition: all 0.5s ease;
}

.leaflet-control-zoom a:hover {
  color: white !important;
  background: rgba(0, 0, 0, 0.9) !important;
}
.card-action {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
</style>
