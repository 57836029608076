<template>
  <div>
    <!-- Modal Structure -->
    <div id="modalReport" class="modal">
      <div class="modal-content">
        <div class="modal-info__title-block index-color">
          <div class="modal-info__wrap-title">
            <div class="modal-info__title">
              <p class="title-mini white-text">Отчеты</p>
            </div>
          </div>

          <div class="modal-info__close modal-close">
            <i class="material-icons white-text"> close</i>
          </div>
        </div>
        <div class="modal-scroll">
          <div class="modal-scroll__btn-create">
            <button
              type="button"
              name="btn_login"
              @click="addNewReport"
              class="col s12 btn btn-large waves-effect blue-grey darken-1"
            >
              Создать новый отчет
            </button>
          </div>
          <div class="modal-scroll__body">
            <table-report />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          name="btn_login"
          class="modal-close col s12 btn btn-large waves-effect mint-green-color"
        >
          ОК
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import M from "materialize-css";

import TableReport from "../Report/TableReport/TableReport.vue";
export default {
  components: { TableReport },
  name: "AppModalBlockHistory",
  data() {
    return {
      modalInstance: null,
      power: 1,
    };
  },
  props: {
    item_light: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  methods: {
    handleClick() {
      this.modalInstance.close();
    },
    addNewReport() {
      console.log("====================================");
      console.log();
      console.log("====================================");
    },
  },
  mounted() {
    const modal = document.querySelector(".modal");
    this.modalInstance = M.Modal.init(modal);
  },
};
</script>
<style scoped>
.modal {
  width: 85% !important;
}
.modal .modal-content {
  padding: 0px;
}
.modal .modal-footer {
  height: initial !important;
}
.font {
  font-weight: 500;
}
.message {
  font-size: 16px;
}
</style>
