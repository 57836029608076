<template>
  <div class="">
    <div class="" style="height: calc(100% - 100px)">
      <div
        class=""
        style="overflow-y: auto; height: calc(100% - 77px)"
        v-if="trackers"
      >
        <item-tracker
          v-for="(itemTrack, index) in trackers"
          :key="itemTrack.id"
          :index="index"
          :item_tracker="itemTrack"
          @zoomToMarker="zoomToMarker"
          @updateChechTracker="updateChechTracker"
          v-model="checkedTracker"
        />
        <!-- @handleClick="handleClick" -->
      </div>
    </div>
    <div class="sidenav__checkbox-all index-color">
      <p>
        <label>
          <input type="checkbox" class="filled-in" v-model="selectAllTracker" />

          <span class="title-text">Выбрать все</span>
        </label>
      </p>
      <div
        class="sidenav__send-button tooltipped modal-trigger"
        data-target="modalAllTimers"
        name="action"
        data-position="top"
        :data-tooltip="'Отправить всем'"
        v-if="this.checkedTracker.length > 1"
      >
        <i class="material-icons white-text right">send</i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { actionTypesTracker } from "@/store/modules/trackers";
import ItemTracker from "./ItemTracker.vue";
export default {
  components: { ItemTracker },
  name: "AppListTracker",
  data() {
    return {
      checkedTracker: [],
    };
  },
  methods: {
    updateChechTracker() {
      this.$store.dispatch(
        actionTypesTracker.checkedTracker,
        this.checkedTracker
      );
    },
    zoomToMarker(item) {
      console.log("====================================");
      console.log(item);
      console.log("====================================");
      this.$emit("zoomToMarker", item);
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.lights.isSubmitting,
      trackers: (state) => state.trackers.allTrackers,
    }),
    selectAllTracker: {
      get: function () {
        return this.trackers
          ? this.checkedTracker.length == this.trackers.length
          : false;
      },
      set: function (value) {
        var checked = [];

        if (value) {
          this.trackers.forEach(function (trackers) {
            checked.push(trackers);
          });
        }

        this.checkedTracker = checked;
        this.$store.dispatch(
          actionTypesTracker.checkedTracker,
          this.checkedTracker
        );
      },
    },
  },
};
</script>

<style></style>
