<template>
  <header>
    <nav class="white">
      <div class="nav-wrapper">
        <div class="brand-logo left header-logo">
          <img src="../assets/svg/LogoLight.svg" alt="logo" class="img-logo" />
        </div>

        <ul
          id="nav-mobile"
          class="right hide-on-med-and-down"
          style="height: 100%"
        >
          <!--Вариант использования модального окна modal-trigger data-target="modalReport" -->
          <li class="" @click="getOpenReport()">
            <router-link class="black-text" :to="'/'"
              ><p>Отчеты</p>
            </router-link>
          </li>
          <li
            class="modal-trigger"
            data-target="modalHistory"
            @click="getHistoryAll()"
          >
<!--            <router-link class="black-text" :to="'/'"-->
<!--              ><p>История</p>-->
<!--            </router-link>-->
          </li>
          <li class="modal-trigger" data-target="modalAboutProgram">
            <router-link class="black-text" :to="'/'"
              ><p>О программе</p>
            </router-link>
          </li>

          <li class="exit-wrap">
            <img
              class="exit-wrap__img black-text"
              src="../assets/svg/logout.svg"
              alt="logout"
              @click="exit()"
            />

            <!-- <a class="black-text" > </a> -->
            <!-- <router-link :to="'/login'" @click="exit()"
              ><p>Выход</p>
            </router-link> -->
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import { actionTypes } from "@/store/modules/auth";
import { actionTypesHistory } from "@/store/modules/history";
import { actionTypesSocket } from "@/store/modules/socket";

export default {
  name: "AppHeader",
  data() {
    return {
      modalInstance: null,
    };
  },
  methods: {
    exit() {
      this.$store
        .dispatch(actionTypes.exitLogin)
        .then(() => {
          this.$store.dispatch(actionTypesSocket.socketClose);
          // socketClose
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    getHistoryAll() {
      this.$store
        .dispatch(actionTypesHistory.getHistoryLogs)
        .then(() => {})
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    getOpenReport() {
      this.$emit("getOpenReport");
      console.log("Инфо ");
    },
  },
  mounted() {},
};
</script>

<style>
/* header {
  padding-left: 300px;
} */
</style>
