const commandDictionary = {
  1: 'Установка номера телефона пользователя',
  2: 'Установка пароля для SMS доступа',
  3: 'Установка часового пояса',
  4: 'Выбор формата SMS сообщения (языка)',
  5: 'Настройка датчика движения и время его срабатывания',
  6: 'Установка времени повторного срабатывания датчика движения',
  7: 'Установка порога оповещения о низком балансе',
  8: 'Установка USSD запроса баланса',
  9: 'Установка режима снупера',
  10: 'Режим поиска (СОС)',
  11: 'Выбор режима передачи информации',
  12: 'Время работы при просыпании прибора',
  13: 'Установка сервера для передачи данных',
  14: 'Установка APN параметров',
  15: 'Команда запроса кода устройства',
  16: 'Команда запроса текущего местоположения',
  17: 'Команда запроса с параметрами работы',
  18: 'Команда перезагрузки устройства',
  19: 'Регулярный пакет',
  20: 'Команда запроса модели устройства и версии прошивки',
  // Добавьте другие команды по необходимости
};

export default commandDictionary;
