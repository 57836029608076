<template>
  <form id="form-general-property" class="form-general-property">
    <div class="modal-info__wrap-margin">
      <p class="title-mini index-color-text">Общие свойства</p>
    </div>
    <div class="row">
      <div class="input-field col s6">
        <input
          id="model"
          name="name"
          type="text"
          v-model="normalizedTracker.name"
          @change="isChangeName = true"
        />
        <label class="active" for="text">Заданое имя</label>
      </div>
      <div class="input-field col s6">
        <input
          disabled
          id="serial"
          type="text"
          v-model="normalizedTracker.serial"
        />
        <label class="active" for="text">Код устройства</label>
      </div>
    </div>
    <div class="row">
      <div class="col s12">
        <label>Модель устройства</label>
        <div class="row">
          <div class="input-field col s4">
            <input
              disabled
              placeholder="Модель"
              id="disabled"
              type="text"
              class="validate"
              v-model="normalizedTracker.gprsResponse.deviceModel"
            />
          </div>
          <div class="input-field col s4">
            <input disabled id="FW" type="text" class="validate" />
            <label for="FW">FW</label>
          </div>
          <div class="input-field col s4">
            <input disabled id="HW" type="text" class="validate" />
            <label for="HW">HW</label>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { actionTypesTracker } from '@/store/modules/trackers'

export default {
  name: "AppGeneralProperties",
  data() {
    return {
      newName: null,
      isChangeName: false,
    };
  },
  props: {
    normalizedTracker: {
      type: Object,
      default() {
        return;
      },
    },
  },
  methods: {
    updateNewSettings() {
      if (this.isChangeName) {
        this.$store
            .dispatch(actionTypesTracker.changeNameTracker,
                { "beaconId": this.normalizedTracker.beaconId,
                  "name":this.normalizedTracker.name })
            .then((response) => {
              this.normalizedTracker.name = response
              this.isChangeName = false
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      }

    },
  },
};
</script>

<style></style>
