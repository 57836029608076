import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/scss/style.scss";
import "materialize-css/dist/css/materialize.min.css";
import "material-design-icons/iconfont/material-icons.css";
import "materialize-css";
import "leaflet/dist/leaflet.css";
import { Datetime } from "vue-datetime";
import DatePicker from "vue2-datepicker";

import "vue2-datepicker/index.css";

import "vue2-datepicker/locale/ru";
// You need a specific loader for CSS files
// import "vue-datetime/dist/vue-datetime.css";
import "vue2-timepicker/dist/VueTimepicker.css";
import dayjs from 'dayjs';
import 'dayjs/locale/ru'; // Импортируем русский язык

dayjs.locale('ru'); // Устанавливаем русский язык как основной



Vue.use(Datetime);
Vue.use(DatePicker);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
