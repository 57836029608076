<template>
  <div>
    <!-- Modal Structure -->
    <div id="modalClose" class="modal modal-fixed-footer">
      <div class="modal-content">
        <div class="modal-info__title-block  index-color">
          <p class="modal-info__title-mini">Вы не сохранили свои изменения</p>
        </div>
        <div class="modal-info__description">
          <p class="modal-info__description-text">Действительно ли вы хотите закрыть насторойки?</p>
        </div>
      </div>
      <div class="modal-footer modal-mini">
        <button
            type="submit"
            name="btn_login"
            @click="allModalClose"
            class="col s12 btn waves-effect index-color"
        >
          Да
        </button>
<!--        modal-close-->
        <button
            type="submit"
            name="btn_login"
            @click="closeModal"
            class=" col s12 black-text btn waves-effect gray-color"
        >
          Нет
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  // import M from "materialize-css";
  // import { actionTypes } from "@/store/modules/lights";

  export default {
    name: "AppModalClose",
    data() {
      return {
        modalClose: null,
      };
    },
    props: {
      item_light: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    methods: {
      handleClick() {
        this.modalInstance.close();
      },
      allModalClose() {
        this.$emit('allModalClose')
      },
      closeModal() {
        this.$emit('closeModalClose')
        console.log('Закрыть')
      },
    }
  };
</script>
<style scoped>
.modal {
  width: 20% !important;
}
.modal .modal-content {
  padding: 0px;
}
.modal .modal-footer {
  height: initial !important;
}
</style>
