import M from "materialize-css";
const state = {
  modalTrackerProperty: null
};
export const mutationTypesModal = {
  setModalTrackerProperty: "[allModal] setModalTrackerProperty",
};
export const actionTypesModal = {
  openModalTracker: "[allModal] openModalTracker",
  closeModalTracker: '[allModal] closeModalTracker'
};

const mutations = {
  [mutationTypesModal.setModalTrackerProperty](state,modalTr) {
    state.modalTrackerProperty = modalTr;
  },
};

const actions = {
  [actionTypesModal.openModalTracker]({ commit }) {
    console.log('Я открыл')
    const modalInstance = M.Modal.init(document.querySelector("#modalTrackerProperty"));
    commit(mutationTypesModal.setModalTrackerProperty, modalInstance);
    modalInstance.open(); // Открыть модальное окно
  },
  [actionTypesModal.closeModalTracker]({ commit }) {
    console.log('Я закрыл')
    if (state.modalTrackerProperty) {
      state.modalTrackerProperty.close(); // Закрыть модальное окно
    }
    commit(mutationTypesModal.setModalTrackerProperty, null);
  },
};

export default {
  state,
  mutations,
  actions
};
