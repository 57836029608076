<template>
  <div class="shema-block margin-sb" v-bind:class="chekBlock">
    <div class="shema-block__title">
      <div class="shema-block__title-wrap" @click="openDrop()">
        <label class="shema-block__checkbox-wrap" @click="zoomToMarker()">
          <input
            type="checkbox"
            class="filled-in black-text"
            :value="item_tracker"
            @change="onChange()"
            v-model="checkedTracker"
          />

          <span class=""></span>
        </label>
        <div class="item-block__info">
          <div class="item-block__info-name">
            <blockquote>
              <div class="item-block__title">
                <div class="shema-block__img">
                  <img
                    src="../../assets/svg/car.svg"
                    alt="car"
                    class="shema-block__img-svg"
                  />
                </div>
                <h6 class="index-color-text">{{ this.item_tracker.name }}</h6>
              </div>
              <div class="item-block__info-options" v-if="!isOpenDropdown">
                <div
                  class="item-block__info-text tooltipped"
                  data-position="bottom"
                  :data-tooltip="'Процент заряда'"
                >
                  <battery :level="this.item_tracker.batteryVoltage" />
                  <p class="index-color-text size-text-12">
                    {{ this.item_tracker.batteryVoltage }}%
                  </p>
                </div>

                <div
                  class="item-block__info-text tooltipped"
                  data-position="bottom"
                  :data-tooltip="'Баланс'"
                >
                  <p class="index-color-text size-text-12">
                    ₽ {{ this.item_tracker.simcardBalance }}
                  </p>
                </div>
                <div
                  class="item-block__info-text tooltipped"
                  data-position="bottom"
                  :data-tooltip="'Скорость'"
                >
                  <img
                    src="../../assets/svg/speed-meter.svg"
                    alt=""
                    class="item-block__img"
                  />
                  <p class="index-color-text size-text-12">
                    {{ this.item_tracker.speed }} км/ч
                  </p>
                </div>
              </div>
            </blockquote>
          </div>
        </div>
      </div>

      <ul
        :id="'dropdown' + this.index"
        class="dropdown-content dropdown-content-update"
      >
<!--        <li-->
<!--          class="modal-trigger"-->
<!--          data-target="modalTrackerProperty"-->
<!--          style="display: flex; align-items: center"-->
<!--        >-->
<!--          <span class="size-text-14 grey-text text-darken-4"> Свойства</span>-->
<!--        </li>-->

<!--        <hr />-->
<!--        class="modal-trigger"-->
<!--        data-target="modalTrackerProperty"-->
        <li
          style="display: flex; align-items: center"
          @click="getCommandTracker(), openModal()"
        >
          <span class="size-text-14 grey-text text-darken-4"
            >Настройки трекера</span
          >
        </li>
        <hr />
        <li
          class="modal-trigger"
          data-target="modalDelete"
          style="display: flex; align-items: center"
        >
          <!-- @click="deleteClick" -->
          <span class="size-text-14 grey-text text-darken-4">Удалить</span>
        </li>
      </ul>

      <i
        class="material-icons dropdown-trigger index-color-text"
        style="cursor: pointer"
        :id="'drop' + this.index"
        :data-target="'dropdown' + this.index"
        @click="handleClick()"
        role="button"
        aria-label="Menu"
        >more_vert</i
      >

    </div>
    <!-- <Transition name="fade" mode="out-in" :duration="550"> -->
    <div class="dropdown__menu shema-block__info" v-if="isOpenDropdown">
      <div class="">
        <p class="info-text">
          Процент заряда:
          <span class="add-text-success w500">
            {{ this.item_tracker.batteryVoltage }} %
          </span>
        </p>
      </div>
      <div class="">
        <p class="info-text">
          Баланс:
          <span class="index-color-text w500">
            {{ this.item_tracker.simcardBalance }} RUB
          </span>
        </p>
      </div>
      <div class="">
        <p class="info-text">
          Скорость:
          <span class="index-color-text w500"
            >{{ this.item_tracker.speed }} км/ч</span
          >
        </p>
      </div>
      <div class="item-block__icon-block">
        <!-- Отпределение локации устройства -->
        <img
          src="../../assets/svg/location.svg"
          class="item-block__icon"
          @click="zoomToMarker()"
        />
      </div>
    </div>
    <!-- </Transition> -->
  </div>
</template>

<script>
import M from "materialize-css";
import Battery from "../Battery.vue";
import { actionTypesTracker } from '@/store/modules/trackers'
import { actionTypesModal } from '@/store/modules/all-modal'
export default {
  components: { Battery },
  name: "AppItemTracker",
  data() {
    return {
      checkedP: false,
      modalInstance: null,
      modalTooltip: null,
      isOpenDropdown: false,
    };
  },
  props: {
    item_tracker: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default() {
        return {};
      },
    },
    value: {
      type: [Array, Object],
      default() {
        return {};
      },
    },
  },
  computed: {
    checkedTracker: {
      get() {
        return this.value;
      },
      set(val) {
        this.checkedP = val;
      },
    },
    chekBlock: function () {
      return {
        "active-track": this.isOpenDropdown,
      };
    },
  },
  methods: {

    onChange() {
      this.$emit("input", this.checkedP);
      this.$emit("updateChechTracker");
    },
    openModal() {
      this.$store.dispatch(actionTypesModal.openModalTracker)
    },
    zoomToMarker() {
      console.log(this.item_tracker, "Click marker");
      this.$emit("zoomToMarker", this.item_tracker);
    },
    handleClick(key) {
      const elems = document.getElementById("drop" + this.index);
      this.modalInstance = M.Dropdown.init(elems);
      this.modalInstance.open(key);
      // Записываем Item
      this.$store
          .dispatch(actionTypesTracker.safeItemTracker, this.item_tracker)
          .then(() => {

          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
    },
    getCommandTracker() {
      //Получение журнала команд определенного трекера
      this.$store
          .dispatch(actionTypesTracker.getAllCommands, this.item_tracker.serial)
          .then(() => {})
          .catch((er) => {
            console.log("Ошибка", er);
          });
    },
    openDrop() {
      this.isOpenDropdown = !this.isOpenDropdown;
    },
  },
  mounted() {
    const elems = document.getElementById("drop" + this.index);
    this.modalInstance = M.Dropdown.init(elems);

    var elemsTool = document.querySelectorAll(".tooltipped");
    this.modalTooltip = M.Tooltip.init(elemsTool);
  },
};
</script>

<style>
fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  /* opacity: 0; */
  /* transition: opacity 0.5s ease; */
}
</style>
